import { useEffect } from 'react';

function NoAllow() {
    useEffect(() => {
        // 禁止右键
        document.oncontextmenu = () => false; // 使用箭头函数替代 Function 构造函数
        
        // 禁止F12
        const handleKeyDown = (event) => {
            if (event.keyCode === 123) {
                alert("F12被禁用");
                event.preventDefault();
            }
            if (event.keyCode === 13) {
                event.keyCode = 505;
            }
            if (event.keyCode === 8) {
                const str = "提示"; // 定义 str 变量
                alert(str + "\n请使用Del键进行字符的删除操作！");
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // 清理副作用
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return null; // 该组件不渲染任何内容
}

export default NoAllow;