import '../index.css'

function ICP(props){
    return(
        <footer className="icps">
        <p>Powered by <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a><br/>{props.admin} . <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">{props.icp}</a></p>
      </footer>
    );
}

export default ICP;